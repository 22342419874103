
import { defineComponent, onMounted } from 'vue';
import AnotherFc from '../services/anotherfunctions';
import Notion from '@/components/Notion.vue'
import SCT from '@/components/SCT.vue'
import Policy from '@/components/Policy.vue'

export default defineComponent({
  name: 'AnotherRegistar',
  components: {
    Notion,
    SCT,
    Policy
  },
  
  data: function(){
    return{
      Open:false,
      Mute:true,
      Start:false,
      Regist:true,
      MailAdress:"",
      NickName:"",
      BirthdayMonth:1,
      Birthday:1,
      Introduction:"",
      PassWordfirst:"",
      PassWordsecond:"",
      AgreeCheck:false,
      NotionOpen:false,
      SCTOpen:false,
      PolicyOpen:false
    }
  },

  methods: {

    NotionOn(){
      this.NotionOpen = true
    },

    NotionClose(Value:boolean){
      this.NotionOpen = Value
    },

    SCTOn(){
      this.SCTOpen = true
    },

    SCTClose(Value:boolean){
      this.SCTOpen = Value
    },

    PolicyOn(){
      this.PolicyOpen = true
    },

    PolicyClose(Value:boolean){
      this.PolicyOpen = Value
    },

    PostRegiatData(){
      if(this.MailAdress == ''){
        alert("メールアドレスを入力してください")
      } else if(this.NickName == "") {
        alert("ニックネームを入力してください")
      } else if( this.PassWordfirst == "" || this.PassWordsecond == ""){
        alert("パスワード欄に未入力があります")
      } else if( !this.PassWordfirst.match(/^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,32}$/)) {
        alert("パスワードは6文字〜32文字の英数字である必要があります。記号、空白は使えません。")
      } else if( this.PassWordfirst != this.PassWordsecond) {
        alert("パスワード欄とパスワード確認欄に違う値が入力されています")
      } else if( this.AgreeCheck == false){
        alert("「上記の利用規約に同意する」にチェックを入れてください")
      } else{
        AnotherFc.sendPostRequest(String(this.MailAdress), String(this.NickName), String(( '00' + this.BirthdayMonth ).slice( -2 )), String(( '00' + this.Birthday ).slice( -2 )), String(this.Introduction), String(this.PassWordfirst), String(this.PassWordsecond), true)
        .then((result) => {
          console.log("response_raw:",result),
          console.log("decoded_body:",result.body!.getReader().read().then((result) => {
            const response = new TextDecoder("utf-8").decode(result.value);
            const decoded_body = JSON.parse(response);
            console.log("response:",decoded_body);
            if(decoded_body.success == 1){
              window.location.href = "https://123chat.jp" + decoded_body.return;
            } else if( decoded_body.errormessage) {
              alert("処理エラー" + decoded_body.errormessage + '#informationFrame')
            }
          }))
        })
        .catch(error => console.log('error', error));
      }
    }
  },

  mounted(){

    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();
  }
});
